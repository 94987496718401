import { useCallback, useEffect, useMemo } from 'react'
import type { SWRConfiguration } from 'swr'
import useSWRInfinite from 'swr/infinite'

import { flatten } from '@livechat/data-utils'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Message } from 'types/assistants'

type MessagesResponse = {
	hasMore: boolean
	lastMessageId: string | null
	messages: Message[]
}

export function useOpenAiThreadMessages(id: string | null | undefined, options?: SWRConfiguration) {
	const { data, mutate, isLoading, error, size, setSize } = useSWRInfinite<MessagesResponse>(
		(index, previousPageData) => {
			if ((previousPageData && !previousPageData.messages.length) || !id) {
				return null
			}

			const after = index === 0 ? '' : `?after=${previousPageData?.lastMessageId}`
			return `${config.apiURL}/v1.0/integrations/openai/threads/${id}/messages${after}`
		},
		{
			revalidateFirstPage: false,
			...options,
		},
	)

	useEffect(() => {
		if (error) {
			store.dispatch({
				type: 'toast/set',
				payload: { kind: 'error', timeout: 6000, message: "We couldn't load history for selected thread" },
			})
		}
	}, [error])

	const loadMoreMessages = useCallback(() => {
		setSize((size) => size + 1)
	}, [setSize])

	const messages = useMemo((): Message[] => {
		if (!data) {
			return []
		}

		return flatten(data.map((value) => value.messages))
	}, [data])

	return {
		messages,
		mutateMessages: mutate,
		messagesError: error,
		areMessagesLoading: isLoading,
		hasMoreMessages: data && data[data.length - 1]?.hasMore,
		loadMoreMessages,
		isLoadingMoreMessages: isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'),
	}
}
