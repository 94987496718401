import styled from '@emotion/styled'

import { useFeature } from '../../../../hooks/useFeature'
import { useOpenAiThreadMeta } from '../../../../hooks/useOpenAiThreadMeta'
import { DEFAULT_OPENAI_AVATAR_URL } from '../../../../lib/constants'
import EmptyArchive from '../../../icons/EmptyArchive'
import MessagesList, { MessagesListSkeleton } from './MessagesList'
import Button from 'components/Button'
import SlideInWrapper from 'components/SlideInWrapper'
import { useOpenAiThreadMessages } from 'hooks/useOpenAiThreadMessages'

const EmptyStateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 320px;
	height: 100%;
	align-self: center;
	text-align: center;
	gap: ${({ theme }) => theme.spaces.space7};
	${({ theme }) => theme.typography.caption}
`

const LoadMoreButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: ${({ theme }) => theme.spaces.space4};
`

function ThreadDetails({ threadId }: { threadId?: string }) {
	const { areMessagesLoading, messages, loadMoreMessages, hasMoreMessages, isLoadingMoreMessages } =
		useOpenAiThreadMessages(threadId)
	const { feature: openaiFeature } = useFeature('openai_integration')
	const { thread } = useOpenAiThreadMeta(threadId)

	if (threadId === '') {
		return (
			<EmptyStateWrapper>
				<EmptyArchive />
				This space is waiting for your assistant&apos;s first chat. Once visitors start interacting with it on your
				website, their conversations will appear here.
			</EmptyStateWrapper>
		)
	}

	if (areMessagesLoading || !messages || !thread || !openaiFeature) {
		return <MessagesListSkeleton />
	}

	return (
		<SlideInWrapper>
			<MessagesList
				messages={messages}
				summary={thread.summary ?? ''}
				botName={openaiFeature.properties.botName}
				botAvatar={openaiFeature.properties.avatarUrl ?? DEFAULT_OPENAI_AVATAR_URL}
			/>
			{hasMoreMessages && (
				<LoadMoreButtonContainer>
					<Button
						type="button"
						kind="secondary"
						compact
						disableHover
						loading={isLoadingMoreMessages}
						onClick={loadMoreMessages}
					>
						Load more
					</Button>
				</LoadMoreButtonContainer>
			)}
		</SlideInWrapper>
	)
}

export default ThreadDetails
