import createSvgIcon from './createSvgIcon'

const Close = createSvgIcon(
	<path d="M13.4142136,12 L15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L12,13.4142136 L9.70710678,15.7071068 C9.31658249,16.0976311 8.68341751,16.0976311 8.29289322,15.7071068 C7.90236893,15.3165825 7.90236893,14.6834175 8.29289322,14.2928932 L10.5857864,12 L8.29289322,9.70710678 C7.90236893,9.31658249 7.90236893,8.68341751 8.29289322,8.29289322 C8.68341751,7.90236893 9.31658249,7.90236893 9.70710678,8.29289322 L12,10.5857864 L14.2928932,8.29289322 C14.6834175,7.90236893 15.3165825,7.90236893 15.7071068,8.29289322 C16.0976311,8.68341751 16.0976311,9.31658249 15.7071068,9.70710678 L13.4142136,12 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Close
